import each from 'lodash/each'
import kebabCase from 'lodash/kebabCase'
import upperFirst from 'lodash/upperFirst'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import find from 'lodash/find'
import get from 'lodash/get'
import some from 'lodash/some'
import pull from 'lodash/pull'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'

import ImagePickLogic from 'core/helpers/images/image-pick-logic'
import { merge, mergeMissing } from 'designer/store/helpers/merge'
import RandomizeSite from './randomize-site'
import Store from '@eig-builder/core-utils/store'
import ColorFunctions from 'core/editor/helpers/color-functions'

///////////////////////////////////
///////////////////////////////////////////////////////
/////////

import { makeTempImageAndPrepareStoringImage } from 'core/helpers/images/upload-replace-image-helper'
//////////////
///////////////////////////////////////////////////
/////////

import GetCurrentLanguage from '@eig-builder/core-utils/helpers/current-language-helper'
import { themeMigration } from 'modules/site/editor/managers/migration-manager/cases/font-size-migration.js' // i'm including the site code, because we need to convert theme's from old sites. Technically passed theme's can also come from the mail but that won't break anything
import GlobalProperties from 'core/helpers/global/global-properties'
import DemoDataManager from 'core/helpers/managers/demodata'
import { getToggleObj } from 'designer/components/popovers/views/property-manager/helpers/get-field-value'

import 'core/lang'
// import CleanDemoData from './clean-demo-data'

const FAVICON_CAT = 'favicon'
let pageIdCounter = 0

const defaultTheme = {
  colors: {
    background: [236, 240, 241],
    text: [42, 51, 59],
    accent: [[240, 147, 43], [255, 191, 117]]
  },
  fonts: {
    body: {
      name: 'Lato',
      weight: 400
    },
    heading: {
      name: 'Montserrat',
      weight: 400
    },
    fontSize: 1
  },
////////////////
///////////////
//////////////
////////////////////////////
////////////////////////////////////
///////////////
//////////////////////
///////////////////////
/////////////////////
//////
//////////////
////////////////////////////
////////////////////////////////////
///////////////
//////////////////////
///////////////////////
/////////////////////
//////
//////////////
////////////////////////////
////////////////////////////////////
///////////////
//////////////////////
///////////////////////
/////////////////////
//////
/////////////////
////////////////////////////
////////////////////////////////////
///////////////
//////////////////////
///////////////////////
////////////////////
//////
/////////////////
////////////////////////////
////////////////////////////////////
///////////////
//////////////////////
///////////////////////
////////////////////
//////
/////////////////
////////////////////////////
////////////////////////////////////
///////////////
//////////////////////
///////////////////////
////////////////////
//////
///////////
////////////////////////////
///////////////
///////////////////
///////////////////////
/////////////////////
/////
////
///////////
////////////////////
///////////////////
////////////////////////
////////////////////////
/////////////////
/////////////////
/////////////////
/////////////////
//////////////////////
////
///////////
  animations: {
    enabled: false
  },
  roundedCorners: false // Albert: wordt dit wel gebruikt?
}

export const defaultGlobalBinding = {
  accounts: {},
  translations: {
    ada: {
      skipToMainText: GlobalProperties.localize('shared.ada.skipToMainContent')
    }
  },
  menuData: [],
  openingHours: {
    mon: [{ description: 'Closed' }],
    tue: [{ description: 'Closed' }],
    wed: [{ description: 'Closed' }],
    thu: [{ description: 'Closed' }],
    fri: [{ description: 'Closed' }],
    sat: [{ description: 'Closed' }],
    sun: [{ description: 'Closed' }]
  },
  address: {},
  logo: {
    value: ''
  },
  phone: '',
  email: '',
  companyName: '',
  title: '',
  callToAction: {
    source: 'global.phone',
    title: 'Call'
  },
  legal: {
    privacyPolicy: {
      showCookieBanner: true,
      bannerPosition: 'bottom',
      bannerText: 'This site uses cookies.',
      agreeButtonText: 'I agree', // TODO: @dvandervlag This should be translated..
      privacyPolicyText: ''
    },
    termsOfService: {
      termsOfServiceText: ''
    }
  },
  description: '',
  coverPhoto: '',
  products: '',
  about: ''
}

const defaultMetadata = {
  siteDescription: '',
  siteKeywords: '',
  socialShareImage: '',
  googleAnalyticsKey: '',
  faviconUrl: '',
  siteName: '',
  siteDomain: '',
  siteHeaderHtml: '',
  siteFooterHtml: '',
  language: ''
}

let idCounter = 0
class CreateSite {
  static handlePropertyDefaults (layoutsObj, binding, isListItemLayout, skipFillDefaults) {
    if (layoutsObj.properties) {
      each(layoutsObj.properties, propArray => {
        propArray
          .filter(i => i.default !== undefined)
          .forEach(property => {
            const propName = property.name
            if (property.isToggle) {
              // toggle properties, preserve between layouts switches (skipFillDefaults)

              if ((!skipFillDefaults && !property.isGlobal) || binding[propName] === '') {
                const _toggle = getToggleObj(binding, isListItemLayout ? 'listElements' : null, true)

                if (typeof _toggle[propName] === 'undefined' && propName !== 'count') {
                  const hasData = (layoutsObj.metadata.demodata && layoutsObj.metadata.demodata[propName]) || (binding[propName] || isListItemLayout)
                  _toggle[propName] = hasData && property.default !== false
                }
              }
            } else {
              // normal properties, always override to default when switching layout
              if (!binding[propName]) {
                binding[propName] = property.default
              }
            }
          })
      })
    }
  }

  static sanitizeSection (section, sectionLayout, listItemLayout) {
    // handle default columns settings
    if (listItemLayout) {
      const { columns } = listItemLayout.metadata
      if (columns) {
        const colCount = columns.default || 4
        section.layout.columns = colCount

        const dataLength = section.binding.list && section.binding.list.length
        if (colCount > 1 && dataLength) {
          // adjust the default columns so the data aligns nicely in the grid
          some([0, 1, -1, 2, -2, 3, -3], spread => {
            const bound = Math.max(columns.minValue || 2, Math.min(columns.maxValue || 4, colCount + spread))
            // Never make the column count 5 since we filter this in the grid manager component
            if (bound !== 5 && dataLength % bound === 0) {
              section.layout.columns = bound
              return true
            }
          })
        }

        if (columns.defaultAlign) {
          section.layout.listItemAlign = columns.defaultAlign
        }
      }
    }
  }

  // TODO: @Jildert, @dvandervlag very discutable code here with what is happening with the bindings here..
  static createSectionPrivate ({ binding, ...sectionData }, layoutsObject, id, demoDataObj, options) {
    const data = {
      ...sectionData
    }

    if (!options.skipTemplateDemoData) {
      data.binding = binding
//////////////
///////////////////////////////////////////////////////////////////////////
/////////
    } else {
      if (binding) {
        // hack to re-use some template properties
        data.binding = data.binding || {}
        data.binding.fullPage = binding.fullPage
      }
    }
    return CreateSite.createSection(data, layoutsObject, id, demoDataObj, options)
  }

  static createSection ({ category, background, binding, navigation, layout }, layoutsObject, id, demoDataObj, options = {}, siteModel = null) {
//////////////////
///////////////////////////////////////////
///////////////////////////////////////
/////////////

//////////////////
///////////////////////////////////////////////////////////////////////////////
/////////////////////////////////
/////

//////////////////////////////////////////////////////////////
/////////////////////////////////////
/////
/////////////

    const sectionLayoutPath = layout.section
    let listItemLayoutPath = layout.listItem
    category = category.toLowerCase()

    const sectionLayout = layoutsObject.get(sectionLayoutPath)

    if (!id) {
      id = idCounter++
    }
    const section = {
      id,
      layout: { section: { id: sectionLayout.id } },
      category
    }
    if (layout.sectionAlign) {
      section.layout.sectionAlign = layout.sectionAlign
    }

    if (sectionLayout.metadata.itemLayout) {
      listItemLayoutPath = sectionLayout.metadata.itemLayout
    }

    let listItemLayout
    if (listItemLayoutPath) {
      listItemLayout = layoutsObject.get(listItemLayoutPath)
      section.layout.listItem = { id: listItemLayout.id }
    }
    if (navigation) {
      section.navigation = navigation // used in section-model
    }

    try {
      // demo data
      let demoCategory = category
      const firstSeperator = category.indexOf('/')
      if (firstSeperator !== -1) {
        demoCategory = category.substr(0, firstSeperator)
      }

      if (demoDataObj && demoDataObj.categories) {
        let demoDataArray = demoDataObj.categories[demoCategory]
        if (!demoDataArray) {
          console.error(`No demo data found for category [${demoCategory}]`)
          demoDataArray = []
        }

        const demoData = demoDataArray || {} // select first demo data to show
        binding = binding || {}

        if (demoData.subtitle) {
          binding._placeholders = binding._placeholders || {}
          binding._placeholders.subtitle = demoData.subtitle
        }

        if (demoData.description) {
          binding._placeholders = binding._placeholders || {}
          binding._placeholders.description = demoData.description
        }

        section.binding = {
          ...demoData,
          ...binding
        }

///////////////
        if (demoCategory === 'images' && demoDataObj && section.binding.list) {
          section.binding.list.forEach(item => {
            item.image = demoDataObj.pickImage(false)
          })
        }
/////////
      } else {
        section.binding = binding || {}
      }

      CreateSite.sanitizeSection(section, sectionLayout, listItemLayout)

      background = background || {}

      // clone demodata so that the reference is lost
      section.binding = cloneDeep(section.binding)

      // Get default contactFrom configuration inside of the section - if it isn't already there
      // if (sectionLayout.metadata.demodata && sectionLayout.metadata.demodata.formOptions && !section.binding.formOptions) {
      //   section.binding.formOptions = sectionLayout.metadata.demodata.formOptions
      // }

      // Add default data for custom layout properties
      CreateSite.handlePropertyDefaults(sectionLayout, section.binding, false, options.skipFillDefaults)
      if (listItemLayout) {
        CreateSite.handlePropertyDefaults(listItemLayout, section.binding, true, options.skipFillDefaults)
      }

      if (get(Store().getState(), 'designer.main.adminParams')) {
        section.binding._toggle = null // enable all toggles
      }

      const isDeveloper = sectionLayout.id.indexOf('devid-') === 0
      let backBinding = section.binding.background

      if (!options.skipFillDefaults || isDeveloper) {
///////////////
        if (sectionLayout.metadata.demodata && (!options.skipLayoutDemoData || isDeveloper)) {
          section.binding = mergeMissing(section.binding, sectionLayout.metadata.demodata)
          // copy background anyway
          if (sectionLayout.metadata.demodata.background) {
            section.binding.background = sectionLayout.metadata.demodata.background
          } else {
            section.binding = merge(section.binding, { background })
          }
        } else {
          section.binding = merge(section.binding, { background })
        }
////////
//////////////////////////////////////////
//////////////////////////////////////////////////////////////////
/////////
/////////
        backBinding = section.binding.background

        // turn on fullpage if we have it in the metadata
        if (sectionLayout.metadata.fullPage) {
          section.binding.fullPage = true
        }

        if (siteModel && category === 'menu') {
          const datasources = siteModel.datasources && siteModel.datasources.singleplatformMenu
          if (datasources) {
            section.binding.dataSource = {
              type: 'singleplatformMenu'
            }
            section.binding.getCollection = true
          }
        }

        if (demoDataObj) {
          if (backBinding.image || sectionLayout.metadata.preferBackgroundImage || (listItemLayout && listItemLayout.metadata.preferBackgroundImage)) {
            backBinding.img = demoDataObj.pickImage(true)
            if (backBinding.img) {
              delete backBinding.image
            }
          }

///////////////
          const hasImage = section.binding.image || (sectionLayout.metadata.demodata && sectionLayout.metadata.demodata.image)
          if (hasImage) {
            section.binding.image = demoDataObj.pickImage(false) || section.binding.image
          }
/////////
        }

        if (backBinding.img && !backBinding.opacity) {
          // default background modification, mimick impressly backgrounds to make text readable on backgrounds
          backBinding.opacity = 50
          backBinding.color = [80, 80, 80]
        }
      }

      if (demoDataObj && !backBinding.img && options.preferBackgroundImage) {
        backBinding.img = demoDataObj.pickImage(true)

        const hasImage = section.binding.image || (sectionLayout.metadata.demodata && sectionLayout.metadata.demodata.image)
        if (hasImage) {
          section.binding.image = demoDataObj.pickImage(false) || section.binding.image
        }

        if (backBinding.img && !backBinding.opacity) {
          // default background modification, mimick impressly backgrounds to make text readable on backgrounds
          backBinding.opacity = 50
          backBinding.color = [80, 80, 80]
        }
      }
    } catch (ex) {
      console.error(`Skipped section in create-site because of layout error: ${sectionLayoutPath}${listItemLayoutPath ? ' - ' + listItemLayoutPath : ''}`)
      console.error(ex)
      if (options.isLayoutMaker) {
        throw ex
      }
      return null
    }

    return section
  }

  static createPageOfType (site, pageTypePath, layoutsObject) {
    const pageType = layoutsObject.pageTypes[pageTypePath]
    if (!pageType) {
      throw new Error('page type not found: ' + pageTypePath)
    }

    let title = pageType.title
    let uriPath = pageType.uri

    if (title === 'Store') {
      title = GlobalProperties.localize('shared.createSite.pages.store')
      uriPath = GlobalProperties.localize('shared.createSite.uri.store')
    } else if (title === 'Product') {
      title = GlobalProperties.localize('shared.createSite.pages.storeProduct')
      uriPath = GlobalProperties.localize('shared.createSite.uri.storeProduct')
    } else if (title.toLowerCase() === 'blog') {
      title = GlobalProperties.localize('shared.createSite.pages.blog')
      uriPath = GlobalProperties.localize('shared.createSite.uri.blog')
    } else if (title.toLowerCase() === 'blog post') {
      title = GlobalProperties.localize('shared.createSite.pages.blogPost')
      uriPath = GlobalProperties.localize('shared.createSite.uri.blogPost')
    }

    const pageSettings = {
      pageTypePath,
      name: title,
      uriPath: uriPath,
      matchUriToTitle: true,
      sections: pageType.sections,
      visibleInNavigation: pageType.visibleInNavigation
    }

    const page = CreateSite.createPage(pageSettings, layoutsObject, null, {}, site.pages[0].headerSection)
    // pageType.sections.forEach((sectionDefinition, index) => {
    //   const mandatory = sectionDefinition.mandatoryForFeature
    //   if (mandatory) {
    //     page.sections[index].mandatoryForFeature = mandatory
    //     page.mandatoryForFeature = mandatory
    //   }
    // })

    return page
  }

  static createPage (
    { name, sections, isMainPage, uriPath, pageTypePath, visibleInNavigation, matchUriToTitle },
    layoutsObject,
    demoDataObj,
    options,
    headerSection
  ) {
    const page = {
      id: new Date().valueOf() + pageIdCounter++,
      title: name,
      sections: (sections || []).map((section, index) => CreateSite.createSectionPrivate(section, layoutsObject, null, demoDataObj, options)).filter(i => i),
      uriPath: uriPath || kebabCase(name),
      showInNavigation: true,
      matchUriToTitle: matchUriToTitle || true,
      mainPage: isMainPage
    }
    if (pageTypePath) {
      page.pageType = pageTypePath
    }
    if (visibleInNavigation === false) {
      page.showInNavigation = false
    }

    // the header section has the special ability that the layout property is synced between all the headerSection of all pages. This section is first rendered in every page.
    if (headerSection) {
      // mainPage gets headerSection with background image, other pages get with color, and will be synced in edit-undo-middleware.js:handleSyncHeaderLayout()
      const { img, patternIndex, color, ...backgroundWithoutImg } = headerSection.binding.background

      const headerSectionClone = cloneDeep(headerSection)

      if (!isMainPage) {
        headerSectionClone.binding.background = backgroundWithoutImg
      }
      headerSectionClone.id = idCounter++
      headerSectionClone.binding.cover = isMainPage
      if (headerSectionClone.binding.fullPage) {
        headerSectionClone.binding.fullPage = false
      }

      page.headerSection = headerSectionClone
    }

    return page
  }

  static createAdminSite (layoutsObject, adminDemoData, options) {
    // const demoData = CreateSite.createDemoDataObj(
    //   {
    //     ...Store().getState().api.demoData.response,
    //     images: [{ url: '//placekitten.com/500/500' }]
    //   },
    //   'aaaaaee5-6883-462d-92d8-9897674913c5'
    // )
    const demoData = CreateSite.createDemoDataObj()

    options = options || {}
    const sections = []

    // if (demodata) {
    //   if (!demodata[FAVICON_CAT]) {
    //     demodata[FAVICON_CAT] = {}
    //   }
    //   // const ignoreFields = ['title', 'subtitle', 'description', 'buttons']
    //   // turn off everything in favicon category except for image field
    //   // ignoreFields.forEach(i => { delete demodata[FAVICON_CAT][i] })
    //   demodata[FAVICON_CAT]._toggle = {
    //     title: false,
    //     subtitle: false,
    //     description: false,
    //     buttons: false
    //   }
    // }

    const printLog = (title, log) => {
      let description = ''
      if (!isEmpty(log)) {
        description += `<br>-------${title}-------:<br>`
        for (const key in log) {
          description += `${key}<br>`
        }
      }
      return description
    }

    let counter = 0

    const innerCreateTemplateSection = (layout, demodataCatName, catDescription) => {
      if (layout) {
        // get parent layout if needed
        let layoutPaths
        const parents = layoutsObject.getParentLayouts(layout)
        if (parents) {
          layoutPaths = {
            section: parents[0].path,
            listItem: layout.path
          }
        } else {
          layoutPaths = {
            section: layout.path
          }
        }

        // const binding = DemoDataManager.createSectionModelForCategory(demodataCatName)

        const log = {
          add: {},
          delete: {}
        }
        // CleanDemoData.log(binding, demodataCatName, layoutsObject, log)

        const description = printLog('Missing', log.add) + printLog('Not used', log.delete)

        sections.push({
          category: 'debug',
          layout: { section: 'debug' },
          binding: { title: catDescription || demodataCatName, description },
          background: { colorIndex: 3 }
        })
        sections.push({
          category: demodataCatName,
          // binding,
          layout: layoutPaths,
          background: { colorIndex: counter++ % 2 }
        })
      }
    }

    const createTemplateSection = catName => {
      const layoutCat = layoutsObject.categories[catName]
      if (layoutCat) {
        const layouts = layoutsObject.getLayoutsToSwitch(layoutCat)
        if (!layouts.every(i => i.metadata && i.metadata.isDeprecated)) {
          const layout = find(layouts, i => i.metadata && i.metadata.useInAdmin) || layouts[0]
          innerCreateTemplateSection(layout, catName, catName)
        }
      }
    }

    // innerCreateTemplateSection(
    //   layoutsObject.get('text/text-1') || layoutsObject.getLayoutsToSwitch('text')[0],
    //   FAVICON_CAT,
    //   'Image is used as default favicon'
    // )

    // innerCreateTemplateSection(
    //   layoutsObject.get('images/images-3') || layoutsObject.getLayoutsToSwitch('galleries')[0],
    //   BACKGROUNDS_CAT,
    //   'Image used as backgrounds'
    // )

    const categories = DemoDataManager.getCategories()
    pull(categories, 'headers', 'footers')
    createTemplateSection('headers')
    categories.forEach(createTemplateSection)
    createTemplateSection('footers')

    const site = {
      theme: defaultTheme,
      pages: [CreateSite.createPage({ name: 'demodata', sections, isMainPage: true }, layoutsObject, demoData, options)],
      globalBinding: CreateSite.getTranslatedDefaultGlobalBinding(),
      metadata: {}
    }

    return site
  }

  static createAllTemplate (layoutsObject, templateName, options) {
    options = options || {}
    let index = 0
    let backgroundColor = 0

    const addSections = (catName, layouts) => {
      const newSections = []
      each(layoutsObject.getLayoutsToSwitch(layouts), layout => {
        const addLayout = (layout, fileName) => {
          const path = fileName ? `${layout.path}/${fileName}` : layout.path
          newSections.push({
            category: 'debug',
            layout: { section: 'debug' },
            binding: { title: path },
            background: { colorIndex: 3 }
          })

          const background = {
            colorIndex: backgroundColor++ % 4
          }
          if (templateName === 'all_no_images') {
            background.image = ''
          }
          const section = {
            category: catName,
            background,
            layout: { section: path }
          }
          const parents = layoutsObject.getParentLayouts(layout)
          if (parents && parents[0]) {
            section.layout.listItem = section.layout.section
            section.layout.section = parents[0].path
          }
          newSections.push(section)
        }
        if (layout.html) {
          addLayout(layout)
        }
        const templates = layout.html_templates || layout.htmlTemplates
        for (const fileName in templates) {
          addLayout(layout, fileName)
        }
      })
      return newSections
    }

    const site = {
      globalBinding: {
        openingHours: {
          mon: '5:30pm - 9:00pm',
          tue: '5:30pm - 9:00pm',
          wed: '5:30pm - 9:00pm',
          thu: '5:30pm - 9:00pm',
          fri: '5:30pm - 9:30pm',
          sat: '5:00pm - 10:00pm',
          sun: '5:00pm - 9:00pm'
        },
        phone: '',
        email: '',
        address: {
          country: 'Netherlands',
          state: 'Friesland',
          city: 'Leeuwarden',
          street: '10 Sophianlaan',
          zip: '1234AB',
          lat: 3534,
          long: 45345
        },
        translations: {
          days: {
            mon: 'Monday',
            tue: 'Tuesday',
            wed: 'Wednesday',
            thu: 'Thursday',
            fri: 'Friday',
            sat: 'Saturday',
            sun: 'Sunday'
          },
          openingHours: 'Business Hours',
          address: 'Address'
        },
        title: `${upperFirst(templateName)} template`,
        logo: {
          value: 'https://vignette.wikia.nocookie.net/spongebob/images/a/a4/Spongebob_SquarePants.jpg/revision/latest?cb=20121212130049'
        }
      },
      theme: merge(defaultTheme, {}),
      pages: map(layoutsObject.categories, (catContent, catName) => {
        const newPage = CreateSite.createPage(
          {
            name: startCase(catName),
            sections: addSections(catName, catContent)
          },
          layoutsObject,
          null,
          options
        )
        newPage.id = index++
        return newPage
      })
    }
    return site
  }

  static CreateColorPaletteFromRGBArray (array) {
    const toArr = rgb => {
      return [rgb.r, rgb.g, rgb.b]
    }

    let colors = array.map(i => ({
      color: i,
      strength: ColorFunctions.colorStrength(i.r, i.g, i.b)
    }))

    colors = orderBy(colors, 'strength')
    const accent = [toArr(colors[colors.length - 1].color)]

    // we have 2 accent colors
    if (colors.length > 1 && colors[colors.length - 2].strength > 0.25) {
      accent.push(toArr(colors[colors.length - 2].color))
    } else {
      accent.push(accent[0].slice(0))
    }

    return {
      background: [236, 240, 241],
      text: [42, 51, 59],
      accent
    }
  }

  static getTranslatedDefaultGlobalBinding = () => {
    const globalBinding = defaultGlobalBinding

    globalBinding.callToAction.title = GlobalProperties.localize('shared.createSite.defaultGlobalBinding.callToAction.title')
    globalBinding.legal.privacyPolicy.agreeButtonText = GlobalProperties.localize('shared.createSite.defaultGlobalBinding.legal.privacyPolicy.agreeButtonText')
    globalBinding.legal.privacyPolicy.bannerText = GlobalProperties.localize('shared.createSite.defaultGlobalBinding.legal.privacyPolicy.bannerText')

    Object.entries(globalBinding.openingHours).forEach(([key, value]) => {
      globalBinding.openingHours[key][0].description = GlobalProperties.localize('shared.createSite.defaultGlobalBinding.hours.closed')
    })
    return globalBinding
  }

  static getShortDescription (description) {
    const minDescriptionLength = 150
    const maxDescriptionLength = 250

    if (description.length > minDescriptionLength) {
      // Find first . or space in the string and cut it there
      let lastCharacterIndex = maxDescriptionLength

      const firstDot = description.slice(minDescriptionLength).indexOf('.')
      const firstSpace = description.slice(minDescriptionLength).indexOf(' ')
      const firstEndCharacter = firstDot || firstSpace
      if (firstEndCharacter > -1) {
        lastCharacterIndex = Math.min(firstEndCharacter + minDescriptionLength + 1, maxDescriptionLength)
      }
      description = description.slice(0, lastCharacterIndex)
    }

    return description
  }

  static createSite (layoutsObject, template, options, demoData, passedTheme) {
//////////////////
////////////////////////////////////////
////////////////////////////////
/////////////
    options = options || {}

    let headerSection
    const globalSections = {}
    if (template.globalSections) {
      each(template.globalSections, (section, name) => {
        if (section) {
          if (name === 'header') {
            const globalSectionOptions = options.createHeaderFromTemplateData ? { ...options, skipTemplateDemoData: false } : options
            headerSection = CreateSite.createSectionPrivate(section, layoutsObject, null, demoData, globalSectionOptions)
            if (options.name) {
              headerSection.binding.title = options.name
            }
            if (options.crawlerData && options.crawlerData.description) {
              const description = options.crawlerData.description
              headerSection.binding.subtitle = CreateSite.getShortDescription(description)
            }
          } else {
            globalSections[name] = CreateSite.createSectionPrivate(section, layoutsObject, null, demoData, options)
          }
        }
      })
    }

    const metadata = {
      companyName: options.name,
      templateName: options.templateName,
      topicId: options.topicId,
      topicQuery: options.topicQuery
    }
    const appmarketApps = options.appmarket_apps || options.appmarketApps
    if (options.crawlParams) {
      metadata.crawlParams = options.crawlParams
    }
    if (options.injectSiteId) {
      metadata.injectSiteId = options.injectSiteId
    }
    if (appmarketApps) {
      // save here so that autosave also knows, we only need this at first real save but we store it on the model anyway so the autosaved version can be re-opened
      metadata.injectedAppInstances = appmarketApps.map(i => ({ sku: i.sku, instanceId: (i.instance_id || i.instanceId) }))
    }

    const globalBinding = merge(CreateSite.getTranslatedDefaultGlobalBinding(), template.globalBinding)
    globalBinding.title = globalBinding.companyName = options.name

    if (demoData) {
      if (demoData.logo) {
        globalBinding.logo = { value: demoData.logo }
        makeTempImageAndPrepareStoringImage(globalBinding.logo, 'value')
      }
      if (demoData.verticalId) {
        // TODO: deprecated could be removed
        metadata.verticalId = demoData.verticalId
      }
      if (demoData.categories[FAVICON_CAT]) {
        metadata.faviconUrl = get(demoData.categories[FAVICON_CAT], '0.image.value')
      }
    }

    if (Store()) {
      const state = Store().getState()
      const email = get(state, 'auth.userEmail')
      if (email) {
        globalBinding.email = email
      }

////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////
/////////////////////////////////////////
///////
///////////////
    }

    let theme
    if (IS_MAIL && template.styleName) {
        // get theme from style
      theme = cloneDeep(ThemeStyles[template.styleName])

      if (!theme) {
        template.styleName = 'calm'
        theme = cloneDeep(ThemeStyles[template.styleName])
      }
      const source = { styleName: template.styleName }
      theme.source = source

      if (passedTheme) {
        themeMigration(passedTheme.theme) // use site migration code to migrate the (old) site theme first. Technically a passedTheme can also come from a mail but that wont break anything
        theme = merge(theme, passedTheme.theme)
        theme.source = { ...source, ...passedTheme.source }
      }
    } else {
      theme = merge(defaultTheme, template.theme)
    }

    const languageCode = GetCurrentLanguage()
    const site = {
      globalSections,
      theme,
      globalBinding,
      pages: map(template.pages, (page, index) => {
        const oldName = page.name

        if (page.name) {
        // translates page name.
          const key = `shared.createSite.pages.${page.name.toLowerCase().replace(/ /g, '')}`
          const translation = GlobalProperties.localize(key)
          if (translation && key !== translation) {
            page.name = translation
          }
        }

        const newPage = CreateSite.createPage(page, layoutsObject, demoData, options, headerSection)

        // get page id from template or match page-uri so we can reference links from demo-data to the right pages
        newPage.id = page.id || oldName.toLowerCase()
        return newPage
      }),
      metadata: merge(defaultMetadata, metadata),
      datasources: {},
      apiKeys: {},
      urls: {},
      language: languageCode
    }

    if (!options.dontRandomize) {
      // randomize everything
      new RandomizeSite(layoutsObject, demoData, CreateSite.sanitizeSection, template).restlyeSections(site)
    }

    if (options.onboardingInput) {
      if (options.onboardingInput.logo) {
        globalBinding.logo = { value: `data:image/svg+xml;charset=utf-8,${options.onboardingInput.logo}` }
      }
      if (options.onboardingInput.colors) {
        site.theme.colors = CreateSite.CreateColorPaletteFromRGBArray(options.onboardingInput.colors)
      }
    }

    return site
  }

  static createDemoDataObj (verticalId, settings = {}) {
    const unsplashImages = DemoDataManager.getImages()

    let demoDataImages
    if (unsplashImages) {
      demoDataImages = unsplashImages.map(i => ({
        url: i.url,
        size: { width: i.width, height: i.height },
        rating: 1,
        alt: (i.alt_description || i.altDescription),
        backgroundRating: 1,
        authorUsername: i.username,
        authorName: i.name
      }))
    }

    const crawledImages = (!settings.forceUseStockImages && settings.crawledImages) || []

    const amountOfDemoDataImages = crawledImages.length
      ? Math.max(10 - crawledImages.length, crawledImages.length / 2) // if we have crawled images: minimal 10 images in total, and 2/3 being crawler images
      : demoDataImages.length

    // group demodata and crawler data images together and shuffel
    let images = crawledImages.concat(demoDataImages.slice(0, amountOfDemoDataImages))

    if (settings.otherSiteImages) {
      images = settings.otherSiteImages.concat(images)
    }

    const imagePickeLogic = new ImagePickLogic(images)

    const categories = DemoDataManager.makeDemoDataModel(imagePickeLogic.pickImage)
    categories.developer = DeveloperInterface.getInstance().getDemoData()

    return {
      images,
      logo: settings.logo,
      categories: categories,
      verticalId,
      pickImage: imagePickeLogic.pickImage
    }
  }
}

export default CreateSite
